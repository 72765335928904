<template>
    <div class="mobile_Report">
        <!-- <img class="logo_move" :src="logoSrc" alt=""> -->
        <div class="logo_move">
            <img :src="logoSrc" alt="" />
        </div>
        <div class="phoneForm">
            <div class="from">
                <ul class="login_verify_li">
                    <li>
                        <div>
                            <el-form :model="numberValidateForm" :rules="rules" ref="numberValidateForm0"
                                class="demo-ruleForm">
                                <el-form-item prop="user" label="账号">
                                    <el-input class="icon1" type="text" v-model="numberValidateForm.user" autocomplete="off"
                                        placeholder="请输入学生账号" maxlength="11">
                                    </el-input>
                                </el-form-item>

                                <el-form-item prop="password" label="密码">
                                    <el-input class="icon2" type="password" v-model="numberValidateForm.password"
                                        autocomplete="off" :show-password="true" placeholder="请输入登录密码" maxlength="11">
                                    </el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </li>

                    <li class="flex-align-center">
                        <div class="loginbtn flex-align-center" @click="submitForm()">
                            登录
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: {},
    data() {
        var validateUser = (rule, value, callback) => {
            if (value == "") {
                callback(new Error("手机号或用户名不能为空"));
            } else if (/\s/.test(value)) {
                callback(new Error("手机号或用户名不能包含空格"));
            } else {
                callback();
            }
        };
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入登录密码"));
            } else {
                callback();
            }
        };
        return {
            numberValidateForm: {
                user: "",
                password: "",
            },

            rules: {
                user: [{
                    required: true,
                    validator: validateUser,
                    trigger: "blur",
                },],
                password: [{
                    required: true,
                    validator: validatePass,
                    trigger: "blur",
                },],
            },
            logoSrc: require("@/assets/login/logo2.png")

        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        async submitForm() {
            if (
                this.numberValidateForm.user == "" ||
                this.numberValidateForm.password == ""
            ) {
                this.$refs.numberValidateForm0.validate();
                return false;
            } else {
                console.log('this.numberValidateForm', this.numberValidateForm);
                let data = {
                    phone: this.numberValidateForm.user,
                    password: this.numberValidateForm.password
                }
                let resData = await this.$Api.Home.login(data);
                if (resData.code === 200) {
                    this.$cookies.set("token", resData.data.access_token, 60 * 60 * 24 * 365 * 10);
                    this.getUserInfo();

                } else {
                    this.handleLoginError(resData.msg);
                    // return false;

                }
            }
        },
        handleLoginError(message) {
            this.$refs.numberValidateForm0.validateField('password', (errorMessage) => {
                if (errorMessage) {
                    console.error(errorMessage);
                } else {
                    this.rules.password.push({
                        validator: (rule, value, callback) => {
                            callback(new Error(message));
                        },
                        trigger: 'blur'
                    });
                    this.$refs.numberValidateForm0.validateField('password');
                }
            })
        },
        async getUserInfo() {
            let resData = await this.$Api.Home.getUserInfo();
            console.log('resData', resData);
            this.$router.push({ path: '/NewReportZhuShouStuList', query: { id: resData.data.id, name: resData.data.name } });

        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {


    },
}
</script>
<style lang="less" scoped>
@import url('./less/report_stu_zhushou_new_login.less');
</style>